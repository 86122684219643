<template>
  <div class="bindtelphone">
    <div>

      <div class="action">
        <el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">

          <el-form-item class="inputitem" prop="oldtelphone" label="原手机号">
            <el-input :readonly="true" placeholder="请输入原手机号码" v-model="ruleForm.oldtelphone"></el-input>
          </el-form-item>

          <el-form-item class="inputitem" prop="telphone" :rules="rules.telphone" label="新手机号">
            <el-input placeholder="请输入新手机号码" v-model="ruleForm.telphone"></el-input>
          </el-form-item>

          <el-form-item prop="code" class="inputitem" :rules="rules.code" label="验证码">
            <el-input placeholder="短信验证码" type="code" v-model="ruleForm.code">
              <i slot="suffix">
                <div class="inputslot">
                  <!-- <div class='line'></div> -->
                  <div class="msgBtn" v-if="timer === 0" @click.prevent="getMsgCode">获取验证码</div>
                  <div class="msgBtn" v-else> {{ timer }}秒内输入</div>
                </div>

              </i>
            </el-input>
          </el-form-item>
          <el-form-item label=" " class="inputitem">
            <el-button type="primary" class="btnsubmit" @click="submitForm('ruleForm')">提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import loginHeader from "@/components/login/loginheader";
import { getTelphoneMsgCode } from "@/api/login";
import { changeTelphone, info } from "@/api/member";

export default {
  components: { loginHeader },
  data() {
    return {
      timer: 0,
      ruleForm: {
        oldtelphone: "",
        telphone: "",
        password: "",
      },
      rules: {
        oldtelphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //验证手机号
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        telphone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          //验证手机号
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],

        code: [
          {
            required: true,
            min: 4,
            max: 4,
            message: "验证码格式不正确",
            traggers: "blur",
          },
        ],
      },
    };
  },

  // watch: {
  //   userinfo: {
  //     handler(v) {
  //       this.ruleForm.oldtelphone = v.phone
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },

  computed: {
    // userinfo: {
    //   get() {
    //     return JSON.parse(this.$store.state.userinfo)
    //   },
    // },
    membersWechatScanId: {
      get() {
        return this.$store.state.membersWechatScanId
      },
      set(v) {
        this.$store.commit('setMembersWechatScanId', v);
      }
    },
    membersId: {
      get() {
        return this.$store.state.membersId
      },
      set(v) {
        this.$store.commit('setMembersId', v);
      }
    },
  },
  mounted() {
    this.getUserInfo()
  },

  methods: {

    getUserInfo() {
      info().then(res => {
        this.ruleForm.oldtelphone = res.data.phone
      })
    },

    getMsgCode() {
      //验证手机号码格式
      var that = this;
      this.$refs.ruleForm.validateField("telphone", function (valid) {
        if (!valid) {
          const params = {
            phone: that.ruleForm.telphone,
          };
          getTelphoneMsgCode(params).then((res) => {
            //发送成功后的处理
            that.timer = 120;
            const timerAction = setInterval(function () {
              if (that.timer > 0) {
                that.timer--;
              } else {
                clearInterval(timerAction);
              }
            }, 1000);
          });
        }
      });
    },


    changePCMode(v) {
      this.loginMode = v;
    },

    submitForm() {
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          const params = {
            oldphone: that.ruleForm.oldtelphone,
            phone: that.ruleForm.telphone,
            code: that.ruleForm.code,
          };
          changeTelphone(params).then((res) => {

            if (res.code === 1) {
              that.$alert(res.message, '换绑手机', {
                confirmButtonText: '确定',
                type: 'success',
                callback: action => {
                }
              })

              that.ruleForm.oldtelphone = ''
              that.ruleForm.telphone = ''
              that.ruleForm.code = ''
              that.getUserInfo()
            }



          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bindtelphone {
  width: 100%;
  min-height: calc(100vh - 180px);
  background: #fff;
}

.bindtitle {
  width: 80%;
  height: 50px;
  background: #ffffff;
  line-height: 50px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}

.action {
  width: 100%;
  height: 232px;
  background: #ffffff;
  margin: 0 auto;
  padding-top: 10px;
  padding-left: 5px;
  box-sizing: border-box;

  .form {
    .inputitem {
      width: 420px;
      margin-top: 20px;
      text-align: left;
    }

    .btnsubmit {
      width: 120px;
    }
  }
}

.inputslot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;

  .msgBtn {
    width: 80px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28a7e1;
    cursor: pointer;
  }

  .line {
    height: 14px;
    width: 1px;
    background: #c5c5c5;
  }
}
</style>